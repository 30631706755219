import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"

const Privacy = () => (
	<Layout
		meta={{
			title: "Aufnahme- und Datenschutzhinweise",
			description:
				"Accento is an amazing Java/JavaScript/Ops online conference",
			path: "/privacy",
		}}
	>
		<Snippet id="privacy-policy" />
	</Layout>
)

export default Privacy
